.video_banner .bg_video {
    position: relative;
    height  : calc(100vh - 2rem);
    overflow: hidden;
}

.video_banner .bg_video::before {
    content   : '';
    position  : absolute;
    top       : 0;
    left      : 0;
    width     : 100%;
    height    : 100%;
    background: radial-gradient(239.41% 166.79% at -53% 100.00%, rgba(1, 32, 99, 0.90) 5%, rgba(0, 0, 0, 0) 77%);
}

.video_banner .bg_video video {
    width          : 100%;
    height         : 100%;
    object-fit     : cover;
    object-position: center;
}

.video_banner .bg_video .banner_img {
    display: none;
    width  : 100%;
}

.video_banner .content {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
}

.video_banner .content .content_inner {
    max-width      : 104rem;
    margin         : 0 auto;
    height         : 100%;
    display        : flex;
    flex-direction : column;
    align-items    : center;
    justify-content: center;
}

.video_banner .content h2 {
    font-weight: 700;
    font-size  : 6rem;
    line-height: 9rem;
    color      : var(--white);
}

.video_banner .content p {
    text-align: center;
    margin    : 2rem 0 5rem;
}


@media (max-width: 991.98px) {
    .video_banner .bg_video {
        /* height: fit-content; */
        height: auto;
    }

    .video_banner .content {
        left : 1.5rem;
        right: 1.5rem;
        width: calc(100% - 3rem)
    }

    .video_banner .content h2 {
        font-size  : 4rem;
        line-height: 5rem;
        padding-top: 2rem;
    }

    .video_banner .content p {
        font-size: 1.6rem;
        line-height: 2.6rem;
        margin: 1rem 0 2rem;
    }
}

@media (orientation: portrait) {
    .video_banner .bg_video .banner_img {
        display: block;
    }

    .video_banner .bg_video video {
        display: none;
    }
}

@media (max-width: 768px) {
    .video_banner .bg_video {
        height: calc(100vh - 2rem);
    }
}


@media (max-width: 575.98px) {
    .video_banner .bg_video {
        height: 59.4rem;
    }

    .video_banner .content {
        width: initial;
    }

    .video_banner .content h2 {
        font-size  : 2.6rem;
        line-height: 5rem;
    }

    .video_banner .content p {
        margin: 1rem 0 3rem;
    }

}