.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    padding: 1.6rem 0;
    z-index: 3;
}

.navbar.bg_dark {
    background: linear-gradient(100deg, #401E5E 11.7%, #1D2E5E 91.4%), #050505;
}

@media (max-width: 575.98px) {
    .navbar {
        padding: 1.5rem 0;
    }
}