.lv_card {
    min-height        : 20.5rem;
    border    : 1px solid transparent;
    border    : 1px solid #F7CC35;
    border-radius     : 1.5rem;
    box-shadow: 0px 0px 10px 0px rgba(247, 204, 53, 0.30);
    background        : rgba(255, 255, 255, 0.10);
    padding           : 4rem 2rem;
    transition: border .3s ease-out, box-shadow .3s ease-in-out;
}

/* .lv_card:hover {
    border    : 1px solid #F7CC35;
    box-shadow: 0px 0px 10px 0px rgba(247, 204, 53, 0.30);
} */

.lv_card_inner {
    height         : 100%;
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
}

.lv_card h4 {
    font-weight   : 700;
    font-size     : 2rem;
    line-height   : 3.5rem;
    letter-spacing: 0.4px;
    color         : var(--white);
    margin-bottom : 1.5rem;
}

.lv_card p {
    font-size  : 1.6rem;
    line-height: 2.6rem;
    text-align : center;
    height: 15rem;
    overflow-y: scroll;
}

.lv_card p::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .lv_card p {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

@media (max-width: 767.98px) {
    .lv_card {
        height : fit-content;
        padding: 2.4rem 1.2rem;
    }

    .lv_card h4 {
        font-size     : 2rem;
        line-height   : 3rem;
        letter-spacing: 0.4px;
        margin-bottom : 1rem;
    }
}