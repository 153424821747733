.scu_sec .grid_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 9rem;
    row-gap: 4.5rem;
}

@media (max-width: 575.98px) {
    .scu_sec .grid_container {
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 4rem;
    }
}
