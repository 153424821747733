.single_service {
    text-align: center;
    padding   : 8rem 7.5rem 8.8rem;
}

.single_service img {
    width : 10rem;
    height: 10rem;
}

.single_service h4 {
    font-weight   : 600;
    font-size     : 3.5rem;
    line-height   : normal;
    text-transform: uppercase;
    color         : var(--white);
    margin-top    : 6.5rem;
}

.single_service .desc_box {
    margin: 2rem 0 6rem;
}

.single_service .desc_box p {
    font-weight: 400;
    font-size  : 1.8rem;
    line-height: 3rem;
    color      : rgba(255, 255, 255, 0.80);
}

.single_service .desc_box p:not(:last-child) {
    margin-bottom: .5rem;
}

@media (max-width: 767.98px) {
    .single_service {
        padding: 3rem 1.5rem;
    }

    .single_service img {
        width : 9rem;
        height: 9rem;
    }

    .single_service h4 {
        font-size : 2.4rem;
        margin-top: 4.5rem;
    }

    .single_service .desc_box p {
        font-size  : 1.6rem;
        line-height: 2.6rem;
    }
}