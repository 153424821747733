.nav_large {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.nav_large .logo_wrapper .logo_lg {
    width: 7.5rem;
}

.nav_large .logo_wrapper .logo_sm,
.nav_large .btn_nst {
    display: none;
}

.nav_large nav>a {
    font-family: 'TT Interphases Pro Trl Variable', sans-serif;
    font-weight   : 400;
    font-size     : 1.8rem;
    line-height   : normal;
    color         : var(--white);
    text-transform: capitalize;
    padding       : 1rem;
    transition    : color .3s ease-in-out;
}

.nav_large nav>a:not(:last-child) {
    margin-right: 2rem;
}

.nav_large nav>a:hover,
.nav_large nav>a.active {
    color: var(--yellow);
}

.nav_large nav>a.active {
    font-weight: 700;
}

.nav_large nav .btn {
    background-color: #6C91BF;
    padding         : 1.3rem 2.5rem;
    transition      : background-color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.nav_large nav .btn span {
    font-size  : 1.5rem;
    line-height: 2.2rem;
    color      : var(--white);
    transition : color .3s ease-in-out;
}

.nav_large nav .btn:hover {
    background-color: var(--yellow);
    box-shadow      : 0px 0px 10px 0px var(--yellow);
}

.nav_large nav .btn:hover span {
    color: var(--black);
}


@media (max-width: 991.98px) {

    .nav_large .logo_wrapper .logo_lg,
    .nav_large nav {
        display: none;
    }

    .nav_large .logo_wrapper .logo_sm,
    .nav_large .btn_nst {
        display: block;
    }

}