.contact_sec .grid_container {
    display              : grid;
    grid-template-columns: 1fr 1.5fr;
    gap                  : 8rem;
}

.contact_sec h5,
.contact_sec h6 {
    font-size  : 2.4rem;
    line-height: 3.5rem;
    color      : var(--white);
}

.contact_sec h5 {
    font-weight: 700;
}

.contact_sec .grid_item:nth-child(1) span {
    font-weight  : 400;
    font-size    : 1.8rem;
    line-height  : 3rem;
    color        : rgba(255, 255, 255, 0.80);
    margin-bottom: .7rem;
}

.contact_sec h6 {
    font-family: 'TT Interphases Pro Trl Variable', sans-serif;
    font-weight: 400;
}

.contact_sec .grid_item:nth-child(1)>*:not(:last-child) {
    margin-bottom: 3rem;
}

.contact_sec .form_wrapper input,
.contact_sec .form_wrapper textarea {
    width           : 100%;
    font-weight     : 400;
    font-size       : 1.6rem;
    line-height     : normal;
    color           : rgba(255, 255, 255, 0.80);
    background-color: transparent;
    border-radius   : 10px;
    border          : 2px solid rgba(255, 255, 255, 0.20);
    padding         : 2.5rem 2.2rem;
}

.contact_sec .form_wrapper input::placeholder,
.contact_sec .form_wrapper textarea::placeholder {
    color: rgba(255, 255, 255, 0.80);
}

.contact_sec .form_wrapper>*:not(:last-child) {
    margin-bottom: 2rem;
}

.contact_sec .form_wrapper .btn {
    display   : inline-block;
    margin-top: 1rem;
}

.contact_sc_top_img {
    position    : relative;
    padding-left: 4.2rem;
}

.contact_sc_top_img::before {
    --left    : 17.6rem;
    content   : '';
    position  : absolute;
    top       : 10px;
    left      : var(--left);
    width     : calc(100% - var(--left));
    height    : 4px;
    background: #F7CC35;
    box-shadow: 0px 0px 10px 0px rgba(247, 204, 53, 0.70);
    z-index   : -1;
}

.contact_sc_top_img img {
    width: 31rem;
}

@media (max-width: 575.98px) {
    .contact_sec .grid_container {
        grid-template-columns: 1fr;
        gap                  : 4rem;
    }

    .contact_sc_top_img::before {
        --left    : 11.7rem;
        top       : 8px;
        left : var(--left);
        width: calc(100% - var(--left));
    }

    .contact_sc_top_img {
        padding-left: 0;
    }

    .contact_sc_top_img img {
        width: 23.4rem;
    }
}