.min-height h5 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.7rem;
  color: var(--white);
}
.min-height span {
  font-family: "TT Interphases Pro Trl Variable", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(255, 255, 255, 0.8);
  margin: 0.5rem 0 2rem;
}
.item-link{
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}
 .padding{
    padding: 10px;
}
.min-height:hover .btn_arrow {
    background-color: var(--yellow);
    transform       : rotate(42deg);
}

.min-height:hover .btn_arrow svg path {
    stroke        : var(--black);
    stroke-opacity: 1;
}

