.social_wrapper {
    display    : flex;
    align-items: center;
}

.social_wrapper>a svg {
    width : 3rem;
    height: 3rem;
}

.social_wrapper>a svg path {
    fill      : #5BC8AF;
    transition: fill .3s ease-in-out;
}

.social_wrapper>a:hover svg path {
    fill: var(--yellow);
}

.social_wrapper>a:not(:last-child) {
    margin-right: 2rem;
}

@media (max-width: 575.98px) {
    .social_wrapper {
        display    : flex;
        align-items: center;
    }

    .social_wrapper>a:not(:last-child) {
        margin-bottom: 0;
        margin-right : 2rem;
    }
}