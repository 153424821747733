.nav_small {
    display: none;
}

@media (max-width: 991.98px) {
    .nav_small {
        display: block;
    }

    .nav_small .nav_smal_overlay {
        position  : fixed;
        top       : 0;
        left      : 0;
        width     : 100%;
        height    : 100%;
        background: rgba(0, 0, 0, 0.50);
        opacity   : 0;
        visibility: hidden;
        transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
    }

    .nav_small.show .nav_smal_overlay {
        opacity   : 1;
        visibility: visible;
    }

    .nav_small .nav_small_content {
        width           : 33rem;
        position        : fixed;
        top             : 0;
        right           : 0;
        transform       : translateX(33rem); 
        background-color: var(--white);
        background      : linear-gradient(100deg, #401E5E 11.7%, #1D2E5E 91.4%);
        padding         : 2rem 1.6rem 4rem;
        transition      : transform .3s ease-in-out;
    }

    .nav_small.show .nav_small_content {
        transform: translateX(0);
    }

    .nav_small .btn_close {
        position: absolute;
        top     : 1.4rem;
        right   : 1.6rem;
        color: var(--white);
    }

    .nav_small .btn_close>svg {
        width : 2rem;
        height: 2rem;
    }

    .nav_small_content .logo_wrapper {
        width: 10rem;
        margin: 0 auto;
    }

    .nav_small_content .logo_wrapper img {
        width: 10rem;
    }

    .nav_small_content nav {
        border-top: 1px solid rgba(211, 211, 211, .3);
        padding     : 3rem 0;
        margin      : 3.5rem 0;
    }

    .nav_small_content nav>a {
        display       : block;
        font-family: 'TT Interphases Pro Trl Variable', sans-serif;
        font-weight: 300;
        font-size: 2.4rem;
        line-height: normal;
        color         : var(--white);
        text-transform: capitalize;
        text-align    : center;
        transition    : color .3s ease-in-out;
    }

    .nav_small_content nav>a:not(:last-child) {
        margin-bottom: 2.5rem;
    }

    .nav_small_content nav>a:hover,
    .nav_small_content nav>a.active {
        font-weight: 700;
        color: var(--yellow);
    }


    .nav_small_content .text_center {
        text-align: center;
    }

    .nav_small_content .btn {
        padding: 1.4rem 2.4rem;
    }

    .nav_small_content .btn span {
        font-size: 1.5rem;
    }
}