.modal {
    z-index: 1060;
}

.modal,
.modal_backdrop {
    position: fixed;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
}

.modal_backdrop {
    background-color: rgba(0, 0, 0, .7);
}

.modal_dialog {
    position      : relative;
    max-width     : 88.4rem;
    margin        : 1.75rem auto;
    pointer-events: none;
}

.modal_dialog_scrollable {
    height: calc(100% - 3.5rem);
}

.modal_dialog_centered {
    display    : flex;
    align-items: center;
    min-height : calc(100% - 3.5rem);
}

.modal_content {
    position      : relative;
    width         : 100%;
    display       : flex;
    flex-direction: column;
    border-radius : 15px;
    background    : linear-gradient(100deg, #401E5E 11.7%, #1D2E5E 91.4%), #000;
    pointer-events: auto;
    z-index       : 2;
}

.modal_dialog_scrollable .modal_content {
    max-height: 100%;
    overflow  : hidden;
}

.modal_dialog_scrollable .modal_body::-webkit-scrollbar {
    display: none;
}

.modal_dialog_scrollable .modal_body {
    overflow-y        : auto;
    -ms-overflow-style: none;
    scrollbar-width   : none;
}

.modal_close {
    position : absolute;
    top      : 1rem;
    right    : 1.5rem;
    font-size: 2.4rem;
    color    : var(--white);
}

@media screen and (max-width: 767.98px) {
    .modal_dialog {
        margin: 1.75rem 1rem;
    }

    .modal_content .title_1 {
        font-size    : 2rem;
        margin-bottom: 2.5rem;
    }

    .modal_body .form_wrapper .grid_2 {
        column-gap: 1rem;
        row-gap   : 1rem;
    }

    .modal_body .form_wrapper form>*:not(:last-child) {
        margin-bottom: 1rem;
    }

    .modal_close {
        top      : .5rem;
        right    : 1rem;
        font-size: 2rem;
    }
}