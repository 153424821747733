.faq_sec .container {
    max-width: 91.5rem;
}

.accordion_item {
    position: relative;
    background: rgba(255, 255, 255, 0.10);
    border-radius: 1.2rem;
    padding   : 3.2rem 4rem;
    overflow: hidden;
}

.accordion_item.open::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: .5rem;
    background-color: var(--yellow);
}

.accordion_item:not(:last-child) {
    margin-bottom: 2rem;
}

.accordion_header h4 {
    position     : relative;
    font-size    : 2rem;
    font-weight  : 500;
    line-height  : 2.7rem;
    color        : var(--white);
    padding-right: 2.4rem;
    cursor       : pointer;
}

.accordion_header h4::after {
    position         : absolute;
    content          : '';
    right            : 0;
    top              : 50%;
    transform        : translateY(-50%);
    width            : 2.4rem;
    height           : 2.4rem;
    background-image: url('../../../../Assets/Icons/faq-plus.svg');
    background-repeat: no-repeat;
    background-size  : 2.4rem;
}

.accordion_item.open .accordion_header h4::after {
    background-image : url('../../../../Assets/Icons/faq-minus.svg');

}


.accordion_body {
    height    : 0;
    overflow  : hidden;
    transition: height .3s ease-in-out;
}

.accordion_item.open .accordion_body {
    height: initial;
}

.accordion_body_inner {
    padding-top: 4rem;
}

.accordion_body p {
    font-family: 'TT Interphases Pro Trl Variable', sans-serif;
    font-weight: 400;
    font-size  : 1.8rem;
    line-height: 3rem;
    color      : rgba(255, 255, 255, 0.80);
}




@media (orientation: portrait) {
    .accordion_item {
        padding: 2rem 1.5rem 2.5rem;
    }

    .accordion_header h4 {
        font-size  : 1.7rem;
    }

    .accordion_header h4::after {
        width          : 2.4rem;
        height         : 2.4rem;
        background-size: 2.4rem;
    }

    .accordion_body_inner {
        padding-top: 2.5rem;
    }

    .accordion_body p {
        font-size: 1.4rem;
    }

}