.leadership_sec .grid_container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 6rem;
    row-gap: 4.5rem;
    align-items: center;
}

.leadership_sec .grid_container>* {
    grid-column: span 2;
}

.leadership_sec .grid_container>*:nth-child(1) {
    grid-column: 2 / 4;
}

.leadership_sec .sc_title_wrapper .desc {
    max-width: 86.2rem;
    margin:  0 auto;
}



@media (max-width: 767.98px) {
    .leadership_sec .grid_container {
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 2.5rem;
    }

    .leadership_sec .grid_container>* {
        grid-column: auto;
    }
    
    .leadership_sec .grid_container>*:nth-child(1) {
        grid-column: auto;
    }
}