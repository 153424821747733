.clients_sec .swiper {
  overflow: visible;
}

.clients_sec img {
  width: 14rem;
}

.title_inline {
  text-transform: capitalize;
}

.portfolio-desc {
  margin-top: 60px;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1.2rem;
  backdrop-filter: blur(125px);
  padding: 3rem 2rem 3.5rem;
  border: 1px solid transparent;
  border: 1px solid #f7cc35;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 10px 0px rgba(247, 204, 53, 0.3);
}
@media (max-width: 1199.98px) {
  .clients_sec .swiper {
    overflow: clip;
  }
}

@media (max-width: 767.98px) {
  .clients_sec img {
    width: 10.8rem;
  }
  
}
