.tms_links {
    display    : flex;
    align-items: center;
    gap        : 1rem;
}

.tms_links a svg {
    width : 2rem;
    height: 2rem;
}

.tms_links a svg path {
    fill      : #5BC8AF;
    transition: fill .3s ease-in-out;
}

.tms_links a:hover svg path {
    fill: var(--yellow);
}