@import url('./Assets/fonts/proxima-nova/ProximaNova.css');
@import url('./Assets/fonts/TTInterphases.css');

:root {
  --containerPadding: 2.4rem;
  --containerWidth  : 120rem;
  --yellow          : #F7CC35;

  --dipBlue: #022949;
  --bgColor: #F8F9FA;
  --gray_1 : #6c6c6c;
}


body {
  font-family: 'ProximaNova', sans-serif;
  font-weight: 700;
  font-size  : 1.6rem;
  line-height: normal;
  background : linear-gradient(100deg, #401E5E 11.7%, #1D2E5E 91.4%), #050505;
}


/* >>== containers start ==<< */
.container,
.container_fluid {
  width  : 100%;
  padding: 0 var(--containerPadding);
}

.container {
  max-width: calc(var(--containerWidth) + (var(--containerPadding) * 2));
  margin   : 0 auto;
}

/* >>== containers end ==<< */


/* >>== paddings start ==<< */
.pt_10,
.py_10 {
  padding-top: 10rem;
}

.pb_10,
.py_10 {
  padding-bottom: 10rem;
}

/* >>== paddings end ==<< */


/* >>== titles start ==<< */
.sc_title_wrapper {
  margin-bottom: 5.5rem;
}

.sc_title_wrapper.center {
  text-align: center;
}

.title_1 {
  font-weight  : 600;
  font-size    : 4rem;
  line-height  : 5.4rem;
  color        : var(--white);
  margin-bottom: 1.8rem;
}

.sc_title_wrapper .desc._568 {
  max-width: 56.8rem;
  margin   : 0 auto;
}

/* >>== titles end ==<< */

/* >>== descriptoin start ==<< */
.desc_box p:not(:last-child) {
  margin-bottom: 2rem;
}

.desc {
  font-family: 'TT Interphases Pro Trl Variable', sans-serif;
  font-weight: 400;
  font-size  : 1.8rem;
  line-height: 3.5rem;
  color      : rgba(255, 255, 255, 0.80);
}

/* >>== descriptoin end ==<< */


/* >>== buttons start ==<< */
.btn {
  display      : inline-block;
  border-radius: 5rem;
}

.btn>span {
  font-family   : 'TT Interphases Pro Trl Variable', sans-serif;
  font-weight   : 400;
  font-size     : 1.8rem;
  line-height   : 2.2rem;
  color         : var(--white);
  text-transform: uppercase;
  transition    : color .3s ease-in-out;
}

.btn:hover>span {
  color: var(--black);
}

.btn.fill {
  background-color: #6636C6;
  padding         : 2rem 3.5rem;
  transition      : background-color .3s ease-in-out, box-shadow .3s ease-in-out;
}

.btn.fill:hover {
  background-color: var(--yellow);
  box-shadow      : 0px 0px 10px 0px #F7CC35;
}

.btn_arrow {
  min-width          : 3.5rem;
  height         : 3.5rem;
  border-radius  : 50%;
  border         : 1px solid rgba(255, 255, 255, 0.20);
  display        : flex;
  justify-content: center;
  align-items    : center;
  transition     : rotate .8s ease-in-out, background-color .3s ease-in-out;
}

.btn_arrow svg {
  width : 2.4rem;
  height: 2.4rem;
}

.btn_arrow svg path {
  transition: stroke-opacity .3s ease-in-out;
}


/* >>== buttons end ==<< */


/* responsive start */
@media (max-width: 575.98px) {
  :root {
    --containerPadding: 1.6rem;
  }

  /* >>== paddings start ==<< */
  .pt_10,
  .py_10 {
    padding-top: 7rem;
  }

  .pb_10,
  .py_10 {
    padding-bottom: 7rem;
  }

  /* >>== titles start ==<< */
  .sc_title_wrapper {
    margin-bottom: 4.5rem;
  }

  .title_1 {
    font-size    : 2.5rem;
    margin-bottom: 1rem;
  }

  .btn>span {
    font-size: 1.6rem;
  }

  /* >>== descriptoin start ==<< */
  .desc {
    font-size  : 1.4rem;
    line-height: 2.7rem;
  }
}