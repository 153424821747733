.service_card {
    position     : relative;
    border-radius: 1.6rem;
    border       : 1px solid rgba(255, 255, 255, 0.20);
    padding      : 7rem 2.4rem 3.5rem;
    transition: border .3s ease-in-out, box-shadow .3s ease-in-out;
}

.service_card:hover {
    border    : 1px solid var(--yellow);
    box-shadow: 0px 0px 10px 0px var(--yellow);
    cursor: pointer;
}

.service_card .icon_box {
    text-align   : center;
    margin-bottom: 7.5rem;
}

.service_card .icon {
    width : 10rem;
    height: 10rem;
    margin: 0 auto;
}

.service_card h5 {
    /* max-width  : 20.8rem; */
    font-weight: 700;
    font-size  : 2.4rem;
    line-height: 3.5rem;
    color      : var(--white);
}

.service_card p {
    height            : 5.2rem;
    font-size         : 1.6rem;
    line-height       : 26px;
    display           : -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow          : hidden;
    margin            : 1.5rem 0 .7rem;
}

.service_card .btn_explore {
    display             : inline-block;
    font-family: 'TT Interphases Pro Trl Variable', sans-serif;
    font-weight         : 400;
    font-size           : 1.4rem;
    font-style          : italic;
    color               : var(--white);
    line-height         : 2.5rem;
    text-decoration-line: underline;
    transition: color .3s ease-in-out;
}

.service_card .btn_explore:hover {
    color: var(--yellow);
}

.service_card .btn_arrow {
    position       : absolute;
    top            : 2.4rem;
    right          : 2.4rem;
  }

.service_card:hover .btn_arrow {
    background-color: var(--yellow);
    transform       : rotate(42deg);
}

.service_card:hover .btn_arrow svg path {
    stroke        : var(--black);
    stroke-opacity: 1;
}



@media (max-width: 767.98px) {
    .service_card .icon_box {
        margin-bottom: 7rem;
    }
    
    .service_card .icon {
        width : 9rem;
        height: 9rem;
    }

    .service_card h5 {
        font-size  : 2rem;
        line-height: 3rem;
    }
}