.policy h5 {
  font-weight:600;
  font-size: 2.5rem;
  line-height: 4rem;
  color: var(--white);
}
.policy .body_text {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 3rem;
}
