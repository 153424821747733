.mtt_sec .flex_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -4.5rem -2rem 0; 
}

.mtt_sec .flex_container>* {
    flex: 0 0 25%;
    width: 25%;
    padding: 0 2rem;
    margin-top: 4.5rem;
}
.member-title{
    font-weight: 600;
    font-size: 3rem;
    line-height: 5.4rem;
    color: var(--white);
    margin-bottom: 1.8rem;
    padding-top: 10px;
}
.others-members .portfolio-desc{
 margin-top: 0px;
}
.others-members .member-title {
 margin-top: 40px;
}
@media (max-width: 575.98px) {
    .mtt_sec .flex_container {
        margin: -4rem 0 0; 
    }
    .mtt_sec .flex_container>* {
        flex: 0 0 100%;
        width: 100%;
        padding: 0 4rem;
        margin-top: 4rem;
    }
}
