.wcu_card_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.wcu_card img {
    width: 9rem;
}

.wcu_card h5 {
    font-weight: 600;
    font-size: 22px;
    line-height: 3.5rem;
    color: var(--white);
    margin: 2.5rem 0 1.5rem;
}

.wcu_card p {
    text-align: center;
    line-height: 3rem;
}

@media (max-width: 575.98px) {
    .wcu_card h5 {
        font-size: 2rem;
        line-height: 3.5rem;
        margin: 2rem 0 1rem;
    }
}
