.consultancy_form {
  padding: 8rem 7.5rem 8.8rem;
}

.modal_icon {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 25px;
}

.cf_title {
  font-weight: 600;
  font-size: 3.5rem;
  line-height: normal;
  text-align: center;
  color: var(--white);
  text-transform: uppercase;
  padding-bottom: 8rem;
}

/* .top-border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
} */
.consultancy_form .form_wrapper label {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: normal;
  color: rgba(255, 255, 255, 0.7);
}

.consultancy_form .form_wrapper input[type="text"],
.consultancy_form .form_wrapper input[type="number"],
.consultancy_form .form_wrapper input[type="email"],
select {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  color: var(--white);
  background-color: transparent;
}

select option {
  background-color: #1d2e5e;
}

.consultancy_form .form_wrapper .form_input {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 2.5rem;
}

.consultancy_form .form_wrapper form > .form_input:not(:last-child) {
  margin-bottom: 6.5rem;
}

.phone_email {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 2rem;
}

.form_btn_wrapper {
  margin: 7rem 0 1rem;
}

.form_btn_wrapper.center {
  text-align: center;
}

@media (max-width: 991.98px) {
  .cf_title {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }

  .consultancy_form .form_wrapper form > .form_input:not(:last-child) {
    margin-bottom: 5.5rem;
  }

  .phone_email {
    grid-template-columns: 1fr;
    row-gap: 3.5rem;
  }
}

@media (max-width: 767.98px) {
  .consultancy_form {
    padding: 3rem 1.5rem;
  }

  .consultancy_form .inner {
    padding: 2rem 1.6rem 4rem;
  }
  .modal_icon {
    display: flex;
    flex-wrap: nowrap;
    gap: 25px;
    align-items: center;
  }
  .cf_title {
    /* padding: 4rem;
    font-size: 2rem;
    line-height: 2.8rem; */
    padding: 2rem;
    font-size: 1.5rem;
    line-height: 2.8rem;
  }
  .consultancy_form .form_wrapper form > .form_input:not(:last-child) {
    margin-bottom: 3.5rem;
  }

  .consultancy_form .form_wrapper label {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .consultancy_form .form_wrapper input[type="text"],
  .consultancy_form .form_wrapper input[type="number"],
  .consultancy_form .form_wrapper input[type="email"],
  select {
    padding: 0.4rem 0;
    font-size: 9px;
    line-height: 11px;
  }

  .form_btn_wrapper {
    margin-top: 5rem;
  }

  .form_btn_wrapper .btn {
    padding: 1.3rem 3.4rem;
  }
  
}
