.services_sec .grid_container {
    display              : grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap           : 4rem;
    row-gap              : 4.5rem;
}

.services_sec .desc_content {
    text-align: center;
    margin-top: 4.5rem;
}

.services_sec .desc_content a {
    font-family   : 'TT Interphases Pro Trl Variable', sans-serif;
    font-weight         : 500;
    font-size           : 1.8rem;
    font-style          : italic;
    color               : var(--white);
    line-height         : 2.5rem;
    text-decoration-line: underline;
}

.services_sec .desc_content>p:not(:last-child) {
    margin-bottom: 2rem;
}

.service_sc_bottom_img {
    position  : relative;
    text-align: right;
}

.service_sc_bottom_img::before {
    content   : '';
    position  : absolute;
    top       : 50%;
    left      : 0;
    transform : translateY(-50%);
    width     : 100%;
    height    : 4px;
    background: #F7CC35;
    box-shadow: 0px 0px 10px 0px rgba(247, 204, 53, 0.70);
    z-index: -1;
}

.service_sc_bottom_img img {
    width: 41rem;
}

@media (max-width: 575.98px) {
    .services_sec .grid_container {
        grid-template-columns: 1fr;
    }

    .services_sec .desc_content a {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    .service_sc_bottom_img img {
        width: 23.4rem;
    }
}