.footer {
    border-top: 1px solid rgba(255, 255, 255, 0.20);
    padding   : 2.5rem 0;
}

.footer .flex_container {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: space-between;
    align-items    : center;
}

.footer p, .footer a {
    font-family: 'TT Interphases Pro Trl Variable', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: rgba(255, 255, 255, 0.80);
}

.footer a {
    position: relative;
}

.footer a:not(:first-child) {
    padding-left: 1.5rem;
}

.footer a:not(:last-child) {
    padding-right: 1.5rem;
}

.footer a:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    width: 2px;
    height: 2rem;
    background-color: rgba(255, 255, 255, 0.80);
}

@media (max-width: 575.98px) {
    .footer_main {
        padding: 4rem 0;
    }
    .footer .flex_container {
        justify-content: center;
        gap: 1.5rem;
    }
}