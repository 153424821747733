@font-face {
    font-family: 'ProximaNova';
    src: url('ProximaNovaA-Thin.woff2') format('woff2'),
        url('ProximaNovaA-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('ProximaNovaA-Light.woff2') format('woff2'),
        url('ProximaNovaA-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('ProximaNova-Regular.woff2') format('woff2'),
        url('ProximaNova-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'ProximaNova';
    src: url('ProximaNova-Bold.woff2') format('woff2'),
        url('ProximaNova-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'ProximaNova';
    src: url('ProximaNova-Extrabld.woff2') format('woff2'),
        url('ProximaNova-Extrabld.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ProximaNova';
    src: url('ProximaNova-Black.woff2') format('woff2'),
        url('ProximaNova-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}




