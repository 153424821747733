.privacy_policy .container > *:not(:last-child) {
  margin-bottom: 5rem;
}

.privacy_policy h2 {
  font-weight: 700;
  font-size: 4rem;
  line-height: 9rem;
  color: var(--white);
  text-align: center;
}
.privacy_policy .content_inner .desc {
  /* border: 1px dashed var(--gray-3); */
  /* text-align: center; */
  /* border-radius: 0.8rem; */
  padding: 1.6rem;
  line-height: 2.5rem;
}
.privacy_policy .content_inner .desc .contact_us{
  padding-top: 25px;
  line-height: 2.5rem;
}
.privacy_policy .content_inner h3 {
  color: var(--white);
  padding-bottom: 10px;
}
.privacy_policy .content_inner span {
padding-left: 40px;
}
.privacy_policy .content_inner h5 {
  font-style: italic;
  color: var(--white);
  margin-left: 40px;
}

.privacy_policy .content_inner p {
  margin-bottom: 20px;
}
.privacy_policy .content_inner p a{
  color: var(--white);
 cursor: pointer;

}

.privacy_policy .container > *:nth-child(1) > p.body_text {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  border-radius: 0.8rem;
  padding: 1.6rem;
}
.privacy_policy h4,
.privacy_policy h5 {
  margin-bottom: 1.8rem;
}
