.tm_details {
    padding: 5rem 3rem 6rem;
}

.tm_details img {
    border-radius: 1.2rem;
    float : left;
    width : 27.8rem;
    max-width: 100%;
    margin: 0 4rem 2rem 0;
}

.tm_details h3 {
    font-weight   : 700;
    font-size     : 3.5rem;
    line-height   : normal;
    color         : var(--white);
    letter-spacing: 0.7px;
    text-transform: uppercase;
}

.tm_details span,
.tm_details label {
    font-family: 'TT Interphases Pro Trl Variable', sans-serif;
    font-weight: 400;
    font-size  : 1.8rem;
    line-height: 3rem;
    color      : rgba(255, 255, 255, 0.80);
}

.tm_details span {
    text-align: center;
    font-style: italic;
    margin    : .5rem 0 2rem;
}


.tm_details .tmd_social {
    margin-top: 5rem;
}

.tm_details label {
    display: inline-block;
    margin-bottom: 1rem;
}

@media (max-width: 767.98px) {
    .tm_details {
        padding: 4rem 1.5rem 3rem;
    }

    .tm_details h3 {
        font-size     : 2.4rem;
        letter-spacing: 0.4px;
    }

    .tm_details .tmd_social {
        margin-top: 3rem;
    }
    .tm_details span, .tm_details label {
        font-family: 'TT Interphases Pro Trl Variable', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 3rem;
        color: rgba(255, 255, 255, 0.80);
    }
}