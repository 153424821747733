.client_card {
  position: relative;
}

.client_card .abs_content {
  width: 34.2rem;
  position: absolute;
  bottom: calc(100% + 2rem);
  left: -38%;
  visibility: hidden;
}

.client_card:hover .abs_content {
  visibility: visible;
}

.client_card .abs_content .content_inner {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1.2rem;
  backdrop-filter: blur(125px);
  padding: 3rem 2rem 3.5rem;
  border: 1px solid transparent;
  border: 1px solid #f7cc35;
  border-radius: 1.5rem;
  box-shadow: 0px 0px 10px 0px rgba(247, 204, 53, 0.3);
}

.client_card .abs_content p {
  font-family: "TT Interphases Pro Trl Variable", sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: rgba(255, 255, 255, 0.8);
}

.client_card .abs_content a,
.client_card .abs_content button {
  font-family: "TT Interphases Pro Trl Variable", sans-serif;
  display: inline-block;
  font-weight: 500;
  font-size: 1.4rem;
  font-style: italic;
  color: var(--white);
  line-height: 2.5rem;
  text-decoration-line: underline;
  margin-top: 2rem;
}

.client_card .abs_content .triangle_down {
  position: absolute;
  bottom: -1.2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 1.6rem solid transparent;
  border-right: 1.6rem solid transparent;
  border-top: 1.2rem solid rgba(255, 255, 255, 0.1);
}
@media (max-width: 767.98px) {
  .client_card .abs_content .content_inner {
    padding: 2.5rem 1.8rem 2.5rem;
  }
}
