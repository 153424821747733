.optivity_way_sec .grid_container {
  display              : grid;
  grid-template-columns: auto 53.9rem;
  column-gap           : 9rem;
}

.optivity_way_sec img {
  width: 100%;
}

.optivity_way_sec nav {
  margin: 3.5rem 0 5.5rem;
}

.optivity_way_sec nav button {
  font-weight   : 400;
  font-size     : 1.6rem;
  line-height   : normal;
  text-transform: capitalize;
  color         : rgba(255, 255, 255, 0.8);
  border-radius : 0.6rem;
  padding       : 1.5rem 2rem;
}

.optivity_way_sec nav button:not(:last-child) {
  margin-right: 2rem;
}

.optivity_way_sec nav button.active {
  color           : var(--black);
  background-color: var(--yellow);
}


@media (max-width: 991.98px) {
  .optivity_way_sec .grid_container {
    grid-template-columns: auto 20rem;
    column-gap           : 4rem;
  }
}

@media (max-width: 575.98px) {
  .optivity_way_sec .grid_container {
    grid-template-columns: 1fr;
    column-gap           : 0;
    row-gap              : 4.5rem;
  }

  .optivity_way_sec .grid_container>*:nth-child(2) {
    order: -1;
  }

  .optivity_way_sec .title_1 {
    text-align: center;
  }

  .optivity_way_sec nav {
    margin: 2rem 0 3.5rem;
  }

  .optivity_way_sec nav button {
    font-size  : 1.2rem;
    line-height: normal;
    padding    : 1.2rem 1.5rem;
  }

  .optivity_way_sec nav button:not(:last-child) {
    margin-right: 1.5rem;
  }
}