.mtt_card {
    border-radius: 1rem;
    overflow     : hidden;
}

.mtt_card_inner {
    position     : relative;
    border       : 1px solid transparent;
    border-radius: 1rem;
    transition   : border-color .3s ease-in-out, box-shadow .3s ease-in-out;
    height: 100%;
}

.mtt_card_inner:hover {
    border-color: var(--yellow);
    box-shadow  : 0px 0px 10px 0px rgba(247, 204, 53, 0.20);
}

.mtt_card img {
    width: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
}

.mtt_card .content {
    position     : absolute;
    left         : 0;
    bottom       : 0;
    width        : 100%;
    border-radius: 7px;
    background   : rgba(0, 0, 0, 0.60);
    padding      : 1.5rem 1rem;
}
.mtt_card .flex_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.min-height{
    min-height: 116px;
}
.mtt_card h5 {
    font-weight: 600;
    font-size  : 2rem;
    line-height: 2.7rem;
    color      : var(--white);
    text-transform: uppercase;
}

.mtt_card span {
    font-family: 'TT Interphases Pro Trl Variable', sans-serif;
    font-weight: 400;
    font-size  : 1.6rem;
    line-height: 2rem;
    color      : rgba(255, 255, 255, 0.80);
    margin     : .5rem 0 2rem;
}

.mtt_card:hover .btn_arrow {
    background-color: var(--yellow);
    transform       : rotate(42deg);
}

.mtt_card:hover .btn_arrow svg path {
    stroke        : var(--black);
    stroke-opacity: 1;
}